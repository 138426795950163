import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/containers/layout";
import Heading from "../components/typography/heading";
import Container from "../components/containers/container";
import Section from "../components/containers/section";
import SEO from "../components/globals/seo";

const TermsWrap = styled(Container)`
  p, li {
    margin-bottom: 20px
  }

  p {
    & ~ strong {
      margin-top: 20px;
    }
    
    &:last-of-type {
      margin-bottom: 0
    }
  }

  li {
    list-style-type: disc;
    margin-left: 24px;
  }

  a {
    font-family: ${p => p.theme.headingFont};
    transition: ${p => p.theme.transitionDefault};

    &:hover {
      color: ${p => p.theme.green};
    }
  }

  strong, bold {
    font-size: ${p => p.theme.baseFontSizeTabMob};

    @media (min-width: ${p => p.theme.screenL}) {
      font-size: ${p => p.theme.baseFontSizeDesktop};
    }
  }
`;

const TermsPage = ({ data }) => {
  const { title, content } = data.prismic.data;

  return (
    <Layout>
      <SEO title="Terms" description="The Terms of TopTekkers" />

      <Section>
        <TermsWrap>
          <Heading>{title.text}</Heading>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: content.html }} />
        </TermsWrap>
      </Section>
    </Layout>
  );
};

export const query = graphql`
{
  prismic: prismicTerms {
    data {
      title {
        text
      }
      content {
        html
      }
    }
  }
}
`;

TermsPage.propTypes = {
  data: PropTypes.object.isRequired,
};


export default withTheme(TermsPage);
